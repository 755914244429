let path = window.location.pathname;
let eventCategory = "dynamics_form";
let form_type = "";
let reportForm = false;
let reportName = "";

if (path.includes("reports")) {
  reportForm = true;
  reportName = document.querySelector(`h1`).innerHTML;
} else if (path.includes("/get-immediate-help")) {
  form_type = "immediate_client_form";
} else if (path.includes("/client-enquiry")) {
  form_type = "client_form";
}

let eventLabel = reportForm ? reportName : form_type;

function pushGa4Event(eCategory, eAction, eLabel) {
  window.dataLayer.push({
    event: "UXevent",
    eCategory: eCategory,
    eAction: eAction,
    eLabel: eLabel,
  });
}

function addEventsToDynamicsForm() {
  const dynamicsForm = document.getElementsByClassName("show-form")[0];
  if (dynamicsForm) {
    pushGa4Event(eventCategory, "view", eventLabel);
    dynamicsForm.addEventListener("click", function clickTrack(event) {
      // only run this event on the first click
      dynamicsForm.removeEventListener("click", clickTrack);
      pushGa4Event(eventCategory, "start", eventLabel);
    });

    dynamicsForm.addEventListener("submit", function (event) {
      pushGa4Event(eventCategory, "submit", eventLabel);
    });
  } else {
    setTimeout(addEventsToDynamicsForm, 1000);
  }
}

function checkCookiesAccepted() {
  if (document.cookie.includes("cookies-set")) {
    if (document.cookie.includes("analytics-cookies-accepted")) {
      addEventsToDynamicsForm();
    }
  } else {
    setTimeout(checkCookiesAccepted, 1000);
  }
}

if (document.querySelector(`div [data-form-block-id]`)) {
  checkCookiesAccepted();
}
